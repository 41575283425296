
import React, { Component } from "react";

import "./Resume.css";
import "./File.css"

import pdfIcon from './assets/Icons/pdfIcon.svg';

import cv from './assets/Images/cv.png';

import downloadCV from './cv_MassimoWeijtens.pdf';

import ProjectInfo from "./File";

export default class Resume extends Component {

    state = {
        resume: false,
    }

    resumeToggle = () => {
        this.setState({
            resume: !this.state.resume,
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                    <span className="Finder--fileSelector">
                        {this.state.resume ? 
                            <span className="Finder--project" style={{backgroundColor: "#185cc9"}}>
                                <img className="project--blue--filter" src={pdfIcon} alt="folder" />
                                <p2>Resume Massimo</p2>
                                <a href={downloadCV} download="CV_MassimoWeijtens">
                                    <ProjectInfo image={cv} url="Resume of Massimo Weijtens" urlInfo="PDF - 142 KB" lastEdit="Tuesday 1 of February 2022" notes="This is the resume of Massimo Weijtens wich is up to date. If you want to you can download it via double pressing the file." />
                                </a>
                            </span>
                            :
                            <span className="Finder--project" onClick={this.resumeToggle}>
                                <img className="project--blue--filter" src={pdfIcon} alt="folder" />
                                <p2>Resume Massimo</p2>
                            </span>
                        }
                    </span>
        );
    }
}