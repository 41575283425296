import React, { Component } from "react";

import "./Finder.css";
import "./File.css"

import Header from "./Header";
import Desktop from "./Desktop";
import Dock from "./Dock";

export default class Home extends Component {

    render() {
        return (
            <div className="App">
                <div className="Desktop">
                    <Header />
                    <Desktop />
                </div>
                <Dock />
            </div>
        );
    }
}