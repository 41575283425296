import React, { Component } from "react";

/* All of the used images */
import avatar from './assets/Images/avatar.png';

/* All of the used icons */
import github from './assets/Icons/githubIcon.svg';
import linkedinIcon from './assets/Icons/linkedinIcon.svg';
import mailIcon from './assets/Icons/mailIcon.svg';
import phoneIcon from './assets/Icons/phoneIcon.svg';

export default class Dock extends Component {

    render() {
        return(
            <div className="Dock">
                <span className="Left flex">
                <a href="tel:+31618653590">
                    <div className="Dock--item" id="Bel">
                    <img src={phoneIcon} id="phone" alt='Github item in the dock' />
                    </div>
                </a>
                <a href="mailto:massimo@weijtens.net">
                    <div className="Dock--item" id="Mail">
                    <img src={mailIcon} id="mail" alt='Github item in the dock' />
                    </div>
                </a>
                </span>
                <span className="Center">
                <img src={avatar} alt="Cartonated version of Massimo Weijtens" />
                </span>
                <span className="Right flex">
                <a href="https://github.com/Massimo-Weijtens">
                    <div className="Dock--item" id="Github">
                    <img src={github} id="github" alt='Github item in the dock' />
                    </div>
                </a>
                <a href="https://linkedin.com/in/Massimo-Weijtens">
                    <div className="Dock--item" id="Linkedin">
                    <img src={linkedinIcon} id="linkedin" alt='Github item in the dock' />
                    </div>
                </a>
                </span>
            </div>
        );
    };
}
