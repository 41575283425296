
import React, { Component } from "react";

import "./Finder.css";
import "./File.css"

import folder from './assets/Icons/folderIcon.svg';

import allepasjes from './assets/Images/allepasjes.PNG';
import independlet from './assets/Images/independlet.jpeg';
import airbnb from './assets/Images/airbnb.jpg';
import martinis from './assets/Images/martinis.jpg';

import ProjectInfo from "./File";

export default class Projects extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: false,
            testemonials: false,
            education: false,
            resume: false,
            skills: false
        }
    }

    klantenkaartToggle = () => {
        this.setState({
            klantenkaartVergeten: !this.state.klantenkaartVergeten,
            IndependLet: false,
            AirbnbClone: false,
            Martinis: false
        });
        console.log("Klantenkaart toggle")
    }

    IndependLetToggle = () => {
        this.setState({
            IndependLet: !this.state.IndependLet,
            klantenkaartVergeten: false,
            AirbnbClone: false,
            Martinis: false
        });
        console.log("Klantenkaart toggle")
    }

    AirbnbToggle = () => {
        this.setState({
            AirbnbClone: !this.state.AirbnbClone,
            IndependLet: false,
            klantenkaartVergeten: false,
            Martinis: false,
        })
    }

    MartinisToggle = () => {
        this.setState({
            Martinis: !this.state.Martinis,
            AirbnbClone: false,
            IndependLet: false,
            klantenkaartVergeten: false,
        })
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                        <span className="Finder--fileSelector">
                            {this.state.klantenkaartVergeten ? 
                                <span className="Finder--project"  onClick={this.klantenkaartToggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Klantenkaart vergeten?</p2>
                                    <a target="_blank" rel="noreferrer" href="https://allepasjes.nl">
                                        <ProjectInfo image={allepasjes} url="Allepasjes.nl" urlInfo="Webpage - 96 Kb" lastEdit="Tuesday 22 December 2021" notes="Google Firebase integration" />
                                    </a>
                                </span>
                                :
                                <span className="Finder--project" onClick={this.klantenkaartToggle}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Klantenkaart vergeten?</p2>
                                </span>
                            }
                            {this.state.IndependLet ? 
                                <span className="Finder--project"  onClick={this.IndependLetToggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Independ-Let</p2>
                                    <a target="_blank" rel="noreferrer" href="http://independ-let.abc-ict.com">
                                        <ProjectInfo image={independlet} url="Independlet.abc-ict.com" urlInfo="Webpage - 53 Kb" lastEdit="Wednesday 20 Oktober 2021" notes="A group project for university with Firebase integration and telegram as message platform." />
                                    </a>
                                </span>
                                :
                                <span className="Finder--project" onClick={this.IndependLetToggle}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Independ-Let</p2>
                                </span>
                            }
                            {this.state.AirbnbClone ? 
                                <span className="Finder--project"  onClick={this.AirbnbToggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Airbnb clone</p2>
                                    <a target="_blank" rel="noreferrer" href="http://airbnb.abc-ict.com">
                                        <ProjectInfo image={airbnb} url="Airbnb.abc-ict.com" urlInfo="Webpage - 15 Kb" lastEdit="Friday 23 Septermber 2020" notes="Orientation project for another customer." />
                                    </a>
                                </span>
                                :
                                <span className="Finder--project" onClick={this.AirbnbToggle}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Airbnb clone</p2>
                                </span>
                            }
                            {this.state.Martinis ? 
                                <span className="Finder--project" onClick={this.MartinisToggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Martini's</p2>
                                    <a target="_blank" rel="noreferrer" href="http://martinis.abc-ict.com">
                                        <ProjectInfo image={martinis} url="Martinis.abc-ict.com" urlInfo="Webpage - 72 Kb" lastEdit="Monday 10 January 2022" notes="A website for a local restaurant in the city center of Eindhoven." />
                                    </a>
                                </span>
                                :
                                <span className="Finder--project" onClick={this.MartinisToggle}>
                                    <img className="project--blue--filter" src={folder} alt="folder" />
                                    <p2>Martini's</p2>
                                </span>
                            }
                        </span>
        );
    }
}