
import React, { Component } from "react";

import "./Education.css";
import "./File.css"

import educationIcon from './assets/Icons/educationIcon.svg';

import sce from './assets/Images/sce.jpg';
import tue from './assets/Images/tue.jpg';

import ProjectInfo from "./File";

export default class Education extends Component {

    constructor(props) {
        super(props);

        this.state = {
            klantenkaartVergeten: this.props.dataParentToChild,
            IndependLet: this.props.dataParentToChild,
            AirbnbClone: this.props.dataParentToChild,
            Martinis: this.props.dataParentToChild,
        }
    }

    sceToggle = () => {
        this.setState({
            sce: !this.state.sce,
            university1: false,
            university2: false,
        });
    }

    university1Toggle = () => {
        this.setState({
            sce: false,
            university1: !this.state.university1,
            university2: false,
        });
    }

    university2Toggle = () => {
        this.setState({
            sce: false,
            university1: false,
            university2: !this.state.university2,
        })
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                        <span className="Finder--fileSelector">
                            {this.state.sce ? 
                                <span className="Finder--project"  onClick={this.sceToggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Stedelijk College Eindhoven</p2>
                                    <ProjectInfo image={sce} url="VWO Nederlandstalige" urlInfo="2013 - 2019" notes="VWO nederlandstalig met als keuze vakken: Spaans, Natuur Leven en Technologie en Wiskunde D." />
                                </span>
                                :
                                <span className="Finder--project" onClick={this.sceToggle}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Stedelijk College Eindhoven</p2>
                                </span>
                            }
                            {this.state.university1 ? 
                                <span className="Finder--project"  onClick={this.university1Toggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Computer Science</p2>
                                    <ProjectInfo image={tue} url="Computer Science and Engineering" urlInfo="Eindhoven University of Technology" lastEdit="2019 - 2020" notes="Computer science is the study of computers and computing as well as their theoretical and practical applications. " />
                                </span>
                                :
                                <span className="Finder--project" onClick={this.university1Toggle}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Computer Science</p2>
                                </span>
                            }
                            {this.state.university2 ? 
                                <span className="Finder--project"  onClick={this.university2Toggle} style={{backgroundColor: "#185cc9"}}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Data Science</p2>
                                    <ProjectInfo image={tue} url="Bachelor Data Science" urlInfo="Eindhoven University of Technology" lastEdit="2020 - 2024" notes="
    Data science is a process that empowers better business decision-making through interpreting, modeling, and deployment." />
                                </span>
                                :
                                <span className="Finder--project" onClick={this.university2Toggle}>
                                    <img className="project--blue--filter" src={educationIcon} alt="folder" />
                                    <p2>Data Science</p2>
                                </span>
                            }
                        </span>
        );
    }
}