import React from "react";
import Draggable from "react-draggable";

/* All of the used icons */
import folder from './assets/Icons/folderIcon.svg';
import OpenEducation from "./OpenEducation";
import OpenProjects from "./OpenProjects";
import OpenResume from "./OpenResume";
import OpenSkills from "./OpenSkills";
import OpenTestemonials from "./OpenTestemonials";


export default class Desktop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: false,
            testemonials: false,
            education: false,
            resume: false,
            skills: false,
            about: true,
        }
    }

    aboutToggle = () => {
        this.setState({
            about: !this.state.about
        });
    }

    projectsToggle = () => {
        this.setState({
            projects: !this.state.projects
        });
    }

    testemonialsToggle = () => {
        this.setState({
            testemonials: !this.state.testemonials
        });
    }

    educationToggle = () => {
        this.setState({
            education: !this.state.education
        });
    }

    resumeToggle = () => {
        this.setState({
            resume: !this.state.resume
        });
    }

    skillsToggle = () => {
        this.setState({
            skills: !this.state.skills
        });
    }


    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return(
            <span className="Desktop--flex">
                {this.state.about ? null : null}
                {this.state.projects ? <OpenProjects toggle={this.projectsToggle} /> : null }
                <Draggable>
                        <span className="Folder">
                            <img src={folder} onTap={this.projectsToggle} onClick={this.projectsToggle} alt="folder" />
                            <p>Projects</p>
                        </span>
                </Draggable>
                {this.state.testemonials ? <OpenTestemonials toggle={this.testemonialsToggle} /> : null}
                <Draggable>
                    <span className="Folder">
                        <img src={folder} onTap={this.testemonialsToggle} onClick={this.testemonialsToggle} alt="folder" />
                        <p>Testemonials</p>
                    </span>
                </Draggable>
                {this.state.education ? <OpenEducation toggle={this.educationToggle} /> : null}
                <Draggable>
                    <span className="Folder">
                        <img src={folder} onTap={this.educationToggle} onClick={this.educationToggle} alt="folder" />
                        <p>Education</p>
                    </span>
                </Draggable>
                {this.state.resume ? <OpenResume onTap={this.resumeToggle} toggle={this.resumeToggle} /> : null}
                <Draggable>
                    <span className="Folder">
                        <img src={folder} onTap={this.resumeToggle} onClick={this.resumeToggle} alt="folder" />
                        <p>Resume</p>
                    </span>
                </Draggable>
                {this.state.skills ? <OpenSkills onTap={this.skillsToggle} toggle={this.skillsToggle} /> : null}
                <Draggable>
                    <span className="Folder">
                        <img src={folder} onTap={this.skillsToggle} onClick={this.skillsToggle} alt="folder" />
                        <p>Skills</p>
                    </span>
                </Draggable>
            </span>
        );
    };
}
