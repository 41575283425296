
import React, { Component } from "react";

import "./Testemonials.css";
import "./File.css"

import testemonialIcon from './assets/Icons/testemonialIcon.svg';

import review1 from "./assets/Images/review1.jpeg";
import review2 from "./assets/Images/review2.jpeg";
import review3 from "./assets/Images/review3.jpeg";
import review4 from "./assets/Images/review4.jpeg";

import ProjectInfo from "./File";

export default class Testemonials extends Component {

    state = {
        review1: false,
        review2: false,
        review3: false,
        review4: false
    }

    review1Toggle = () => {
        this.setState({
            review1: !this.state.review1,
            review2: false,
            review3: false,
            review4: false
        });
    }

    review2Toggle = () => {
        this.setState({
            review1: false,
            review2: !this.state.review2,
            review3: false,
            review4: false
        });
    }

    review3Toggle = () => {
        this.setState({
            review1: false,
            review2: false,
            review3: !this.state.review3,
            review4: false,
        })
    }

    review4Toggle = () => {
        this.setState({
            review1: false,
            review2: false,
            review3: false,
            review4: !this.state.review4,
        })
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                    <span className="Finder--fileSelector">
                        {this.state.review1 ? 
                            <span className="Finder--project"  onClick={this.review1Toggle} style={{backgroundColor: "#185cc9"}}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 1 - Mieke</p2>
                                <ProjectInfo image={review1} url="Redesign of my website" urlInfo="8.5 out of 10" lastEdit="Tuesday 22 December 2021" notes="Redesigned my website and listened for my input and adviced if it was a good idea or not." />
                            </span>
                            :
                            <span className="Finder--project" onClick={this.review1Toggle}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 1 - Mieke</p2>
                            </span>
                        }
                        {this.state.review2 ? 
                            <span className="Finder--project"  onClick={this.review2Toggle} style={{backgroundColor: "#185cc9"}}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 2 - Giovanni</p2>
                                <ProjectInfo image={review2} url="Independlet.abc-ict.com" urlInfo="Webpage - 53 Kb" lastEdit="Wednesday 20 Oktober 2021" notes="Massimo was a team player who thought 5 steps ahead for every decision we made. Which lifted the project to a better level." />
                            </span>
                            :
                            <span className="Finder--project" onClick={this.review2Toggle}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 2 - Giovanni</p2>
                            </span>
                        }
                        {this.state.review3 ? 
                            <span className="Finder--project"  onClick={this.review3Toggle} style={{backgroundColor: "#185cc9"}}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 3 - Clinton</p2>
                                <ProjectInfo image={review3} url="Airbnb.abc-ict.com" urlInfo="Webpage - 15 Kb" lastEdit="Friday 23 Septermber 2020" notes="We made this website together for students who have entry level experience in webdevelopment. The collabaration with Massimo went very smooth en he had some nice ideas or tips." />
                            </span>
                            :
                            <span className="Finder--project" onClick={this.review3Toggle}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 3 - Clinton</p2>
                            </span>
                        }
                        {this.state.review4 ? 
                            <span className="Finder--project" onClick={this.review4Toggle} style={{backgroundColor: "#185cc9"}}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 4 - Merijn</p2>
                                <ProjectInfo image={review4} url="Martinis.abc-ict.com" urlInfo="Webpage - 72 Kb" lastEdit="Monday 10 January 2022" notes="When I contacted Massimo I had no clue what for kind of website I wanted. However with discussing all of the options we came to the best website possible." />
                            </span>
                            :
                            <span className="Finder--project" onClick={this.review4Toggle}>
                                <img className="project--blue--filter" src={testemonialIcon} alt="folder" />
                                <p2>Review 4 - Merijn</p2>
                            </span>
                        }
                    </span>
        );
    }
}