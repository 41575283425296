
import React, { Component } from "react";

import "./Education.css";
import "./File.css"

import skillsIcxon from './assets/Icons/skillsIcon.svg';

export default class Education extends Component {

    constructor(props) {
        super(props);

        this.state = {
            klantenkaartVergeten: this.props.dataParentToChild,
            IndependLet: this.props.dataParentToChild,
            AirbnbClone: this.props.dataParentToChild,
            Martinis: this.props.dataParentToChild,
        }
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                        <span className="Finder--fileSelector">
                            <span className="Finder--project" onClick={this.sceToggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Patient</p2>
                            </span>
                            <span className="Finder--project" onClick={this.university1Toggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Eager to learn</p2>
                            </span>
                            <span className="Finder--project" onClick={this.sceToggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Precise</p2>
                            </span>
                            <span className="Finder--project" onClick={this.university1Toggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Being able to keep an overview</p2>
                            </span>
                            <span className="Finder--project" onClick={this.sceToggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Neatly</p2>
                            </span>
                            <span className="Finder--project" onClick={this.university1Toggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Ambitious</p2>
                            </span>
                            <span className="Finder--project" onClick={this.university1Toggle}>
                                <img className="project--blue--filter" src={skillsIcxon} alt="folder" />
                                <p2>Enterprising</p2>
                            </span>
                        </span>
        );
    }
}