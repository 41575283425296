

import { MobileView , BrowserView} from 'react-device-detect';
import './App.css';

/* All of the used components */
import Home from "./Home";

function App() {

  return (
    <div>
      <BrowserView>
        <Home />
      </BrowserView>
      <MobileView>
        <h1>This is still under construction</h1>
      </MobileView>
    </div>
  );
}

export default App;
