
import React, { Component } from "react";

import "./Finder.css";
import "./File.css"

import folder from './assets/Icons/folderIcon.svg';
import testemonialIcon from './assets/Icons/testemonialIcon.svg';
import educationIcon from './assets/Icons/educationIcon.svg';
import pdfIcon from './assets/Icons/pdfIcon.svg';
import skillsIcxon from './assets/Icons/skillsIcon.svg';
import Draggable from "react-draggable";
import Projects from "./Projects";
import Testemonials from "./Testemonials";
import Education from "./Education";
import Resume from "./Resume";
import Skills from "./Skills";

export default class OpenSkills extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: false,
            testemonials: false,
            education: false,
            resume: false,
            skills: true
        }
    }

    projectsToggle = () => {
        this.setState({
            projects: !this.state.projects,
            testemonials: false,
            education: false,
            resume: false,
            skills: false
        });
    }

    testemonialsToggle = () => {
        this.setState({
            projects: false,
            testemonials: !this.state.testemonials,
            education: false,
            resume: false,
            skills: false
        });
    }

    educationToggle = () => {
        this.setState({
            projects: false,
            testemonials: false,
            education: !this.state.education,
            resume: false,
            skills: false
        });
    }

    resumeToggle = () => {
        this.setState({
            projects: false,
            testemonials: false,
            education: false,
            resume: !this.state.resume,
            skills: false
        });
    }

    skillsToggle = () => {
        this.setState({
            projects: false,
            testemonials: false,
            education: false,
            resume: false,
            skills: !this.state.skills
        });
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
                <Draggable>
                    <div className="Finder">
                        <span className="Finder--title">
                            <div id="Red" onClick={this.handleClick}/>
                            <div id="Orange" />
                            <div id="Green" />
                        </span>
                        <span className="Finder--nav">
                            <p1 id='Finder--nav--name'>Desktop</p1>
                            <span className="Finder--nav--item" onClick={this.projectsToggle}>
                                <img className="Blue--filter" src={folder} alt="folder in the menu of finder" />
                                <p2>Projects</p2>
                            </span>
                            <span className="Finder--nav--item" onClick={this.testemonialsToggle}>
                                <img className="Blue--filter" src={testemonialIcon} alt="folder in the menu of finder" />
                                <p2>Testemonials</p2>
                            </span>
                            <span className="Finder--nav--item" onClick={this.educationToggle}>
                                <img className="Blue--filter" src={educationIcon} alt="folder in the menu of finder" />
                                <p2>Education</p2>
                            </span>
                            <span className="Finder--nav--item" onClick={this.resumeToggle}>
                                <img className="Blue--filter" src={pdfIcon} alt="folder in the menu of finder" />
                                <p2>Resume</p2>
                            </span>
                            <span className="Finder--nav--item" onClick={this.skillsToggle}>
                                <img className="Blue--filter" src={skillsIcxon} alt="folder in the menu of finder" />
                                <p2>Skills</p2>
                            </span>
                        </span>
                        <span className="Finder--title--files">
                            <p1 className="Finder--title--desktop">Desktop</p1>
                        </span>
                            {this.state.projects ? <Projects toggle={this.projectsToggle} /> : null }
                            {this.state.testemonials ? <Testemonials toggle={this.testemonialsToggle} /> : null}
                            {this.state.education ? <Education toggle={this.educationToggle} /> : null}
                            {this.state.resume ? <Resume toggle={this.resumeToggle} /> : null}
                            {this.state.skills ? <Skills toggle={this.skillsToggle} /> : null}
                    </div>
                </Draggable>
        );
    }
}