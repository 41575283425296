
import React from "react";

import "./File.css";

const ProjectInfo = ({ image, url, urlInfo, lastEdit, notes}) => {
    
    return (
        <div className="file">
            <div className="file__image">
                <img className="file__image__logo" src={image} alt="Logo of the website" />
            </div>
            <div className="file__description">
                <h2>{url}</h2>
                <p1>{urlInfo}</p1>
                <h2>Information</h2>
                <p1>Last edit:</p1>
                <h2 id='right'>{lastEdit}</h2>
                <p1>Notes:</p1>
                <h2 id='right'>{notes}</h2>
            </div>
        </div>
    );
}

export default ProjectInfo;